import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { DataGrid, GridSearchIcon } from "@mui/x-data-grid";
import { Grid, Typography, IconButton, Button, CircularProgress, Tooltip, TextField, Alert } from "@mui/material";
import { Delete, Email, FileCopy, AddCircle, Settings, Update } from "@mui/icons-material";

import { GridPadding } from "../../mainStyle.js";
import Layout from "../../layout/layout.js";
import { getProjects, sendProjectToMail, duplicateProject, deleteProject } from "./projectListController.js";
import { ModalDialog } from "../shared/modals/modalDialog.js";
import { Format, _t } from "../../store/translate.js";
import debounce from "lodash.debounce";
import { editProjectSettings, getJsonData, getProjectSettings } from "../projectSettings/projectSettingsController.js";
import { ProjectListStyles } from "./projectListStyle.js";

const SearchBar = (props) => {
	return (
		<TextField
			style={{ width: "100%" }}
			variant="standard"
			onChange={props.onChange}
			placeholder={_t("Suchen...")}
			InputProps={{
				startAdornment: <GridSearchIcon fontSize="medium" />,
			}}
		/>
	);
};

function ProjectList(props) {
	const navigate = useNavigate();
	const [projectToUpdate, setProjectToUpdate] = React.useState(null);
	const [jsonUpdateConfimationDialogOpen, setJsonUpdateConfimationDialogOpen] = React.useState(false);
	const [currentJsonData, setCurrentJsonData] = React.useState({});
	const [selectedProject, setSelectedProject] = React.useState({});
	const [deleteModal, setDeleteModal] = React.useState(false);
	const [loading, setLoading] = React.useState(true);
	const [pageLoading, setPageLoading] = React.useState(true);
	const [createSendModal, setCreateSendModal] = React.useState(false);
	const [toSend, setToSend] = React.useState({ id: "", mail: "", name: "" });
	const [openModal, setOpenModal] = React.useState(false);
	const [modalText, setModalText] = React.useState({ title: "", text: "" });
	let [updateEvent, setUpdateEvent] = React.useState(0);
	let [queryParams, setQueryParams] = React.useState({
		filter: {},
		select: ["name", "total_area", "created_at", "updated_at", "project_address", "json_data"],
		options: {
			skip: 0,
			limit: 10,
			sort: {
				updated_at: -1,
			},
		},
	});

	const [rows, setRows] = React.useState([]);

	const columns = [
		{
			field: "name",
			headerName: _t("Projekte"),
			width: 500,
			headerAlign: "center",
			align: "left",
		},
		{
			field: "project_address",
			headerName: _t("Ort"),
			width: 200,
			headerAlign: "center",
			align: "center",
			valueFormatter: (params) => params.city,
		},
		{
			field: "area",
			sortable: true,
			headerName: _t("Fläche m²"),
			width: 150,
			headerAlign: "center",
			align: "center",
			renderCell: (params) =>
				!params.value ? (
					<Tooltip title={_t("Keine Flächen angegeben!")}>
						<Alert severity="warning">{Math.round(params.value)}</Alert>
					</Tooltip>
				) : (
					<div>{Math.round(params.value)}</div>
				),
		},
		{
			field: "num_of_floors",
			sortable: true,
			headerName: _t("Geschosse"),
			width: 100,
			headerAlign: "center",
			align: "center",
			renderCell: (params) =>
				!params.value ? (
					<Tooltip title={_t("Keine Geschosse angegeben!")}>
						<Alert severity="warning">{params.value}</Alert>
					</Tooltip>
				) : (
					<div>{params.value}</div>
				),
		},
		{
			field: "num_of_rooms",
			sortable: true,
			headerName: _t("Räume"),
			width: 100,
			headerAlign: "center",
			align: "center",
			renderCell: (params) =>
				!params.value ? (
					<Tooltip title={_t("Keine Räume angegeben!")}>
						<Alert severity="warning">{params.value}</Alert>
					</Tooltip>
				) : (
					<div>{params.value}</div>
				),
		},
		{
			field: "updated_at",
			headerName: _t("Aktualisiert am"),
			width: 200,
			headerAlign: "center",
			align: "center",
			valueFormatter: (value) => moment(value).format("DD.MM.YYYY HH:mm"),
		},
		{
			field: "created_at",
			headerName: _t("Erstellt am"),
			width: 200,
			headerAlign: "center",
			align: "center",
			valueFormatter: (value) => moment(value).format("DD.MM.YYYY HH:mm"),
		},
		{
			field: "json_data",
			headerName: _t("Datenstand"),
			width: 200,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => {
				return (
					<div>
						{moment(params.row.created_at).format("DD.MM.YYYY")}
						{new Date(params.row.created_at) < new Date(currentJsonData.created_at) && (
							<Tooltip title={_t("Produktdatensatz aktualisieren")}>
								<IconButton
									edge="end"
									aria-label="update"
									onClick={(e) => {
										e.stopPropagation();
										setProjectToUpdate(params.id);
										setJsonUpdateConfimationDialogOpen(true);
									}}
									size="large"
								>
									<Update fontSize="small" color="error" />
								</IconButton>
							</Tooltip>
						)}
					</div>
				);
			},
		},
		{
			field: "actions",
			headerName: _t("Aktionen"),
			width: 200,
			headerAlign: "center",
			align: "center",
			renderCell: (params) => (
				<div>
					<Tooltip title={_t("Einstellungen")}>
						<IconButton
							color="inherit"
							edge="end"
							aria-label="settings"
							onClick={(e) => {
								navigate("/projectsettings/" + params.id);
							}}
							size="large"
						>
							<Settings />
						</IconButton>
					</Tooltip>
					<Tooltip title={_t("Senden")}>
						<IconButton
							color="info"
							edge="end"
							aria-label="email"
							onClick={(e) => {
								setToSend({ id: params.id, mail: "", name: params.row.name });
								setCreateSendModal(true);
							}}
							size="large"
						>
							<Email />
						</IconButton>
					</Tooltip>
					<Tooltip title={_t("Kopieren")}>
						<IconButton
							color="info"
							edge="end"
							aria-label="copy"
							onClick={async (e) => {
								copyProject(params.id);
							}}
							size="large"
						>
							<FileCopy />
						</IconButton>
					</Tooltip>
					<Tooltip title={_t("Löschen")}>
						<IconButton
							color="error"
							edge="end"
							aria-label="delete"
							onClick={(e) => {
								setSelectedProject(params.row);
								setDeleteModal(true);
							}}
							size="large"
						>
							<Delete />
						</IconButton>
					</Tooltip>
				</div>
			),
		},
	];

	const handleCellClick = async (event, navigate) => {
		var id = event.row._id;
		if (event.field !== "actions") {
			navigate("/project/" + id);
		}
	};

	const deleteProjectOnConfirmation = async () => {
		var id = selectedProject.id;
		var request = await deleteProject(id);
		if (request && request.deletedCount === 1) {
			setUpdateEvent(Math.floor(Math.random() * 1000));
		}
	};

	const updateProjectJson = async () => {
		try {
			let p = await getProjectSettings(projectToUpdate);
			await editProjectSettings({ data: { ...p, json_data: currentJsonData._id } }, p._id);
			setProjectToUpdate(null);
			setUpdateEvent(Math.floor(Math.random() * 1000));
		} catch (err) {
			alert(_t("Beim Aktualisieren des Datenstands dieses Projekts ist ein Fehler aufgetreten. bitte wenden Sie sich an den Administrator."));
			console.error(err);
		}
	};

	const copyProject = async (id) => {
		setPageLoading(true);
		var request = await duplicateProject(id);
		if (request && request._id) {
			setUpdateEvent(Math.floor(Math.random() * 1000));
		}
	};

	const SendModal = (
		<Grid container spacing={1} sx={GridPadding}>
			<TextField
				value={toSend.mail}
				label={_t("Email")}
				variant="outlined"
				onChange={(event) => {
					setToSend({ id: toSend.id, mail: event.target.value, name: toSend.name });
				}}
			/>
		</Grid>
	);
	const handleClick_SendProjectOnConfirmation = async () => {
		setLoading(true);
		let response = await sendProjectToMail(toSend.id, toSend.mail);
		if (response) {
			let title = toSend.name;
			let text = response;
			setModalText({ title, text });
			setOpenModal(true);
			setLoading(false);
			if (props.user.user.email === toSend.mail) {
				window.location.reload();
			}
		}
	};

	const handleSortModelChange = (sortModel) => {
		let sort = sortModel.reduce((a, s) => {
			if (s.field === "json_data") {
				a[`${s.field}.created_at`] = s.sort;
			} else if (s.field === "project_address") {
				a[`${s.field}.city`] = s.sort;
			} else {
				a[s.field] = s.sort;
			}
			return a;
		}, {});
		setQueryParams((prev) => ({ ...prev, options: { ...prev.options, sort } }));
	};

	// const handlePageChange = (pageObject) => {
	// 	const page = pageObject.page ? pageObject.page - 1 : 0;
	// 	const pageSize = pageObject.pageSize ? pageObject.pageSize : 0;
	// 	setPageSize(pageSize);
	// 	setPage(page);
	// 	setQueryParams((prev) => ({ ...prev, options: { ...prev.options, skip: page * pageSize, limit: pageSize } }));
	// };

	var debounceSearch = debounce((e) => {
		let searchValue = e.target.value;
		setQueryParams((prev) => ({
			...prev,
			filter: searchValue ? { $or: [{ name: { $regex: searchValue, $options: "i" } }, { "project_address.city": { $regex: searchValue, $options: "i" } }] } : {},
		}));
	}, 500);

	async function fetchData() {
		setPageLoading(true);
		let j = await getJsonData();
		setCurrentJsonData(j);
		var res = await getProjects(props.user?.user?._id, queryParams);
		let projects = res.projects;
		if (projects && projects.length) {
			projects.forEach((project) => {
				project.id = project._id;
			});
			setRows(projects);
		} else {
			setRows([]);
		}
		setPageLoading(false);
		setLoading(false);
	}

	React.useEffect(() => {
		fetchData();
	}, [queryParams, updateEvent]);
	// }, [queryParams, updateEvent]);//TODO fix pagination, needed?

	return (
		<Layout>
			{loading && <CircularProgress style={{ position: "absolute", left: "50%", top: "50%" }} />}
			{jsonUpdateConfimationDialogOpen && (
				<ModalDialog
					vendor={props.vendor}
					opened={jsonUpdateConfimationDialogOpen}
					closed={setJsonUpdateConfimationDialogOpen}
					title={_t("Produktdatensatz aktualisieren")}
					text={_t(
						"Mit diesem Vorgang werden die Produkdaten auf den neuesten Stand aktualisiert. Da nicht gewährleistet werden kann, dass die vorhandenen Projekteinstellung exakt auf die neuen Produktdaten anzuwenden sind, sind abweichende Ergebnisse nicht auszuschließen und eine manuelle Kontrolle der Projekteinstellungen nötig."
					)}
					acceptbutton={updateProjectJson}
				/>
			)}
			{deleteModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={setDeleteModal}
					title={Format(_t("%1 permanent löschen?"), [selectedProject.name])}
					acceptbutton={deleteProjectOnConfirmation}
				/>
			)}
			{createSendModal && (
				<ModalDialog
					vendor={props.vendor}
					opened={true}
					closed={() => {
						setCreateSendModal(false);
					}}
					title={_t("Projekt senden:")}
					acceptbutton={handleClick_SendProjectOnConfirmation}
					children={SendModal}
				/>
			)}
			{openModal && <ModalDialog vendor={props.vendor} opened={true} closed={setOpenModal} title={modalText.title} text={modalText.text} />}
			{!loading && (
				<Grid item xs={12}>
					<Grid container justifyContent="space-between" sx={GridPadding}>
						<Grid item>
							<Typography component="h5" variant="h5">
								{_t("Projektübersicht")}
							</Typography>
						</Grid>
						<Grid item xs={4}>
							<SearchBar onChange={debounceSearch}></SearchBar>
						</Grid>
						<Grid item>
							<Button variant="contained" color="primary" href="/projectSettings/addNewProject">
								<AddCircle />
								<Typography variant="body1" style={{ paddingLeft: "5px" }}>
									{_t("Neues Projekt")}
								</Typography>
							</Button>
						</Grid>
					</Grid>
					<ProjectListStyles>
						<DataGrid
							rows={rows}
							pagination
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 10,
									},
								},
							}}
							pageSizeOptions={[10, 15, 20]}
							columns={columns}
							loading={pageLoading}
							onSortModelChange={handleSortModelChange}
							onCellClick={(e) => {
								handleCellClick(e, navigate);
							}}
							disableColumnMenu
							disableRowSelectionOnClick
						/>
					</ProjectListStyles>
				</Grid>
			)}
		</Layout>
	);
}

export default ProjectList;
